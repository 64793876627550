<template>
  <div class="">
    <div class="jq22-head-chang">
      <div class="jq22-flex">
        <div class="jq22-user-img">
          <van-image width="100%" height="100%" :src="list.user_pic" />
        </div>
        <div class="jq22-flex-box">
          <h2>账号:{{ list.account }}</h2>
          <span>会员等级:{{ list.grade_name }}</span>
        </div>

        <van-button round type="info" @click="clear">退出</van-button>
      </div>
    </div>
    <div class="">
      <div class="jq22-white-box">
        <div class="jq22-flex" style="border-bottom: 1px dashed #ccc">
          <div class="jq22-flex-box">
            <h2>我的订单</h2>
          </div>
          <router-link :to="{ path: '/order/all' }" class="jq22-arrow">
            <span>查看全部</span>
          </router-link>
        </div>
        <div class="jq22-palace">
          <router-link
            :to="{ path: '/order/payment' }"
            class="jq22-palace-grid"
          >
            <div class="jq22-palace-grid-icon">
              <img src="../../assets/img/nav-001.png" alt="" />
            </div>
            <div class="jq22-palace-grid-text">
              <h2>未支付</h2>
            </div>
          </router-link>
          <router-link
            :to="{ path: '/order/delivery' }"
            class="jq22-palace-grid"
          >
            <div class="jq22-palace-grid-icon">
              <img src="../../assets/img/nav-002.png" alt="" />
            </div>
            <div class="jq22-palace-grid-text">
              <h2>已支付</h2>
            </div>
          </router-link>
          <router-link
            :to="{ path: '/order/complete' }"
            class="jq22-palace-grid"
          >
            <div class="jq22-palace-grid-icon">
              <img src="../../assets/img/nav-003.png" alt="" />
            </div>
            <div class="jq22-palace-grid-text">
              <h2>已完成</h2>
            </div>
          </router-link>
        </div>
      </div>
      <van-cell-group>
        <van-cell title="我的积分" :value="list.money" label-class="active" />
        <!-- <van-cell title="我的地址" is-link to="/address" /> -->
        <van-cell title="积分明细" is-link to="/integral" />
        <van-cell title="重置密码" is-link to="/pass" />
        <van-cell
          title="客服微信"
          :value="list.tel_phone"
          label="点击复制"
          @click="textCopy(list.tel_phone, $event)"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
//replace
import copyText from './copy'
export default {
  data () {
    return {
      list: {
        account: ''
      }
    }
  },

  created () {
    this.$store.dispatch('genxUserInfoAsync').then(() => {
      this.$nextTick(() => {
        this.list = Object.assign({}, this.list, this.$store.state.login.userInfo);

        console.log(this.list.account)
      })

    });
  },
  methods: {
    textCopy (text, event) {
      copyText(text, event)
    },
    onEdit () {

    },
    clear () {
      this.$store.dispatch('clearLoginAsync').then(() => {
        this.$Notify('退出成功');
        this.$router.replace('/')
      })
    }
  }
}
</script>

<style>
.jq22-head-chang {
  background: -webkit-linear-gradient(left, #3cabec, #634bf6);
  background: -o-linear-gradient(right, #3cabec, #634bf6);
  background: -moz-linear-gradient(right, #3cabec, #634bf6);
  background: linear-gradient(to right, #3cabec, #634bf6);
  background-color: #d7bc85;
  padding: 1rem 0 2rem 0;
}

.jq22-flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
}
.jq22-flex-box h2 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: normal;
  font-size: 1.1rem;
  margin: 0;
}
.jq22-flex-box span {
  color: rgba(255, 255, 255, 0.9);
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}
.jq22-user-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.7);
  margin-right: 0.5rem;
}
.van-cell__value {
  color: red;
}
.jq22-white-box {
  background: #fff;
  border-radius: 5px;
  width: 94%;
  margin: 0 auto 0.8rem;
  margin-top: 10px;
  border: 1px solid rgb(239, 239, 239);
}
.jq22-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.5em;
  padding-bottom: 5px;

  position: relative;
}

.jq22-white-box .jq22-flex-box h2 {
  color: #343434;
  font-weight: bold;
  font-size: 0.92rem;
}
.jq22-arrow {
  position: relative;
  padding-right: 0.8rem;
}
.jq22-arrow span {
  font-size: 0.8rem;
  color: #9b9b9b;
}
.jq22-arrow:after {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  border-width: 2px 2px 0 0;
  border-color: #9b9b9b;
  border-style: solid;
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  position: relative;
  top: -2px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 2px;
  border-radius: 1px;
}
.jq22-palace {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.jq22-palace-grid {
  position: relative;
  float: left;
  padding: 1px;
  width: 20%;
  box-sizing: border-box;
  margin: 5px 0;
}
.jq22-palace-grid-icon {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}
.jq22-palace-grid-icon img {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}
.jq22-palace-grid-text {
  display: block;
  text-align: center;
  color: #333;
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.2rem;
}
.jq22-palace-grid-text h2 {
  font-size: 0.8rem;
  font-weight: normal;
  color: #666666;
}
</style>